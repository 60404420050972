<script setup lang="ts">
import ClassicEditor from "@/base-components/Ckeditor/ClassicEditor.vue";
import TomSelect from "@/base-components/TomSelect";
import Button from "@/base-components/Button";
import { useI18n } from "vue-i18n";
import { useLocalize } from "@/locale/i18n";
import {
  FormInput,
  FormLabel,
  FormSwitch,
  InputGroup,
} from "@/base-components/Form";
import Tab from "@/base-components/Headless/Tab";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { GET_JOB_PAGE } from "@/lib/graphql/queries/job";
import { useQuery, useMutation } from "@vue/apollo-composable";
import LoadingSpinner from "@/components/Loading";
import { SET_JOB } from "@/lib/graphql/mutations/job";
import { inject, ComputedRef } from "vue";
import { initJobsPage } from "./initialJobs";
import { useLazyQuery } from "@vue/apollo-composable";
import { GET_ALL_JOBS } from "@/lib/graphql/queries/job";
import { usePagination } from "@/lib/pagination";
import { columns, actionsDropdown, filterFields, toolbar } from "./tableConfig";
const editorData = ref("");
const editorConfig = {
  language: "ar",
};

// Localization
const { isRTL, i18n, language } = useLocalize();

const jobData = ref<IJobPage>(initJobsPage);

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IJobObject>(() =>
  lang.value === "ar" ? "ar" : "en"
);

type JobApi = {
  getJobs: {
    jobs: IJobPage[];
    totalRecords: number;
    slug: string;
  };
};

const {
  loading: getAllJobLoading,
  result,
  refetch,
  load,
} = useLazyQuery<JobApi>(GET_ALL_JOBS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<JobApi>(
  result,
  "getJobs",
  refetch
);

load(null, { pagination: getPaginationVariables() });

// const jobs = computed<IJobs[]>(() => result.value?.getJobs || []);

const jobs = computed<Object[]>(() => {
  const jobs = result.value?.getJobs.jobs.map(
    (job) => {
      return { jobOpeningId: job.jobOpeningId, city: job.city, slug: job.slug };
    }
  );
  return jobs;
});

// const settingsData = inject<ISettings>("settingsData", {} as ISettings);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-jobs");
});
watch(
  isRTL,
  () =>
    (document.title = i18n("routes.app") + " | " + i18n("routes.website-jobs"))
);

const { result: JobsPage, loading: getJobLoading } = useQuery(
  GET_JOB_PAGE,
  null,
  {
    fetchPolicy: "no-cache",
  }
);

provide("jobData", jobData);
provide("objAccessor", objAccessor);

// Watch for the servicePage result and update servicePageData
watchEffect(() => {
  if (JobsPage.value?.getJobPage) {
    jobData.value = {
      ...jobData.value,
      ...JobsPage.value?.getJobPage,
    };
  }
});

const { mutate: setJobsPage, loading: setJobLoading } = useMutation(SET_JOB);

const handleSubmit = () => {
  const data = { ...jobData.value };
  console.log("sethoem", data);
  setJobsPage({ data });
};

const loading = computed(
  () => setJobLoading.value || getJobLoading.value || getAllJobLoading.value
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-lg md:text-xl font-medium">
            {{ $t("website.jobs.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.jobs.description") }}
          </p>
        </div>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <!-- end of  hero section title -->
        <!--Hero Title En and Ar -->
        <div>
          <div class="grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("common.title") }}
              </FormLabel>
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="jobData[objAccessor].title"
              />
            </div>

            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("common.paragraph") }}
              </FormLabel>
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="jobData[objAccessor].paragraph"
              />
            </div>
          </div>
        </div>
        <!-- End Hero Title En and Ar -->
        <!--Hero paragraph En and Ar -->

        <!-- End Hero paragraph En and Ar -->
        <div class="mt-5 text-right">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
        <data-list
          tableTitle="common.jobs"
          :rows="jobs"
          :columns="columns"
          :loading="loading"
          :withPagination="true"
          :pagination="pagination"
          class="!px-0 !mt-0"
        >
          <!-- <template #costumeCreateNew>
              <AddFaqModal @faq-added="refetch()" />
            </template> -->

          <template #default="{ row }">
            <router-link
              class="flex items-center gap-2 whitespace-nowrap"
              :to="`website-jobs/${row?.jobOpeningId}`"
            >
              <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
              <!-- Open -->
            </router-link>

            <!-- <AddFaqModal :row="row" @faq-added="refetch()">
                <template #trigger>
                  <Icon
                    icon="solar:pen-new-square-broken"
                    class="h-5 w-5 cursor-pointer"
                  />
                </template>
              </AddFaqModal> -->
          </template>
        </data-list>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
