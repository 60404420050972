import gql from "graphql-tag";
import { ImageFragment, MetaTagsFragment } from "./base-fragments";


export const MemberLocalizedFragment = gql`
  fragment MemberLocalizedFragment on MemberLocalized {
    fullName
    title
    brief
    slug
    profilePic {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const MemberFragment = gql`
  fragment MemberFragment on MemberModel {
    authId
    en {
      ...MemberLocalizedFragment
    }
    ar {
      ...MemberLocalizedFragment
    }
    id
    email
    linkedin

    role
    phoneNumber_1
    isEnabled
    isDeleted
    createdAt
    updatedAt
    order
    metaTags {
      ...MetaTagsFragment
    }
  }

  ${MetaTagsFragment}
  ${MemberLocalizedFragment}
`;


