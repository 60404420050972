// TABLE COLUMNS
export const columns = [
  // {
  //   field: "fullName",
  //   label: "table.fullName",
  //   type: "string",
  // },
  {
    field: "en.profilePic",
    label: "table.avatar",
    type: "img",
    align: "center",
  },
  // {
  //   field: "title",
  //   label: "table.title",
  //   type: "string",
  // },
  {
    field: "phoneNumber_1",
    label: "table.phoneNumber",
    type: "string",
  },
  {
    field: "linkedin",
    label: "table.linkedIn",
    type: "string",
  },
  {
    field: "email",
    label: "table.email",
    type: "string",
  },
  {
    field: "role",
    label: "table.role",
    type: "string",
  },
  {
    field: "isEnabled",
    label: "table.status",
    type: "status",
    colorClass: {
      true: "text-success bg-green-100/75",
      false: "text-danger bg-red-100/75",
    },
    status: {
      true: "common.activated",
      false: "common.disabled",
    },
    localized: true,
  },
  {
    field: "createdAt",
    label: "table.createdAt",
    type: "date",
  },
  {
    field: "action",
    label: "table.actions",
    type: "action",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];
//ACTIONS DROPDOWN
export const actionsDropdown = ["deleteAll"];

//FILTER
export const filterFields = [
  {
    label: "common.name",
    field: "name",
    type: "text",
    orderBy: "name",
    operator: "Equal",
  },

  {
    label: "common.startDate",
    field: "startDate",
    type: "date",
    orderBy: "id",
    operator: "Equal",
  },
  {
    label: "common.endDate",
    field: "dateRange",
    type: "dateRange",
    orderBy: "id",
  },

  {
    label: "common.status",
    field: "status",
    type: "list",
    orderBy: "id",
    operator: "Equal",

    options: [
      {
        value: "enabled",
        label: "common.enabled",
      },
      {
        value: "disabled",
        label: "common.disabled",
      },
    ],
  },
];

// TOOLBAR
export const toolbar = {
  maxWidth: 400,
};

// DUMMY ROWS
export const rows = [
  {
    id: "Rn2",
    fullName: "Girgis nabil",
    status: "enabled",
    title: "Software Engineer",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
  {
    id: "Rn23",
    fullName: "Girgis nabil",
    status: "enabled",
    title: "Software Engineer",

    avatar:
      "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
  {
    id: "Rn25",
    fullName: "Girgis nabil",
    status: "disabled",
    title: "Software Engineer",

    avatar:
      "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
];
// export const rows = [];
