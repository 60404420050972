import { SocialType } from "@/types/member";
export const getInitialMember = (): IMember => ({
  linkedin: "",

  authId: "",
  ar: {
    fullName: "",
    title: "",
    brief: "",
    slug: "",
    profilePic: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  },
  en: {
    fullName: "",
    title: "",
    brief: "",
    slug: "",
    profilePic: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  },
  role: "",
  email: "",
  order: "",
  phoneNumber_1: "",
  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
});
