import production from "./production";
import development from "./development";
import staging from "./staging";

let config = staging;
switch (import.meta.env.VITE_ENVIRONMENT) {
  case "production":
    config = production;
    break;
  case "development":
    config = development;
    break;
  case "staging":
    config = staging;
    break;
}
// @ts-ignore

export default config;
